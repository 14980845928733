<template>
  <div class="header-holder">
    <header :style="colorArea">
      <div class="header-coluna-esq">
        <h2 class="title-with-strap white">{{ title }}</h2>
        <div class="breadcrumb-wrapper">
          <div class="breadcrumb">
            <router-link to="/home" class="bcRouter"> Home </router-link>
            <router-link
              v-if="breadcrums.area_id != null"
              :to="
                '/area/' +
                breadcrums.area_id +
                '-' +
                breadcrums.area_name.replaceAll(' ', '-').toLowerCase()
              "
              class="bcRouter"
            >
              {{ breadcrums.area_name }}
            </router-link>
            <router-link
              v-if="breadcrums.category_id != null"
              :to="
                '/categoria/' +
                breadcrums.category_id +
                '-' +
                breadcrums.category_name.replaceAll(' ', '-').toLowerCase()
              "
              class="bcRouter"
            >
              {{ breadcrums.category_name }}
            </router-link>
            <router-link
              v-if="breadcrums.family_id != null && breadcrums.family_id != '0'"
              :to="
                '/familia/' +
                breadcrums.family_id +
                '-' +
                breadcrums.family_name.replaceAll(' ', '-').toLowerCase()
              "
              class="bcRouter"
            >
              {{ breadcrums.family_name }}
            </router-link>
            <router-link
              v-if="breadcrums.product_id != null"
              :to="
                '/produto/' +
                breadcrums.product_id +
                '-' +
                breadcrums.product_name.replaceAll(' ', '-').toLowerCase()
              "
              class="bcRouter"
            >
              {{ breadcrums.product_name }}
            </router-link>
            <a v-if="breadcrums.final_place != null" class="bcRouter">
              {{ breadcrums.final_place }}
            </a>
          </div>
        </div>
      </div>

      <div class="header-coluna-dir">
        <!-- <div id="icon-cart-header-div" @click="goToCart()" style="color:#fff !important;" v-if="showCartIcon">
                    <i id="icon-search-header" class="fas fa-shopping-cart"></i>
                    <img id="icon-cart-header" src="@/assets/imagens/icone-carrinho.svg" :class="{invertColor: invertIconColor}">
                    <span class="count-cart-items" v-if="cartItems > 0">{{cartItems}}</span>
                </div> -->
        <div
          @click="searchBoxShow()"
          style="color: #fff !important"
          v-if="showSearchIcon"
        >
          <i id="icon-search-header" class="fas fa-search"></i>
        </div>
        <div v-if="user_name" @click="updateUserShow()">
          <i id="icon-user-header" class="far fa-user"></i>
        </div>
        <div v-if="!user_name" @click="loginForm()">
          <i id="icon-user-header" class="far fa-user"></i>
        </div>
        <div class="texto-font-2 white">Emotional Technology</div>
        <!-- <div class="title-with-strap white">Guide</div> -->
      </div>
    </header>
    <AreaUser />
    <SearchBox />
  </div>
</template>

<script>
  import AreaUser from "@/components/Area.vue";
  import SearchBox from "@/components/SearchBox.vue";
  export default {
    name: "Header",
    props: ["title", "breadcrums", "cartItems"],
    components: {
      AreaUser,
      SearchBox,
    },
    data() {
      return {
        n: 0,
        user_name: window.sessionStorage.getItem("name"),
      };
    },
    methods: {
      async goToCart() {
        this.$router.push("/cart");
      },
      logOut: async function () {
        window.sessionStorage.clear();
        this.$cookies.remove("username");
        this.$cookies.remove("token");
        window.location.href = "/";
      },
      updateUserShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#novo-nome").value = window.sessionStorage.name;
        document.querySelector("#novo-email").value =
          window.sessionStorage.email;

        document.querySelector("#area-screen").style.display = "block";

        if (document.querySelector("#icon-user-header") != null) {
          document.querySelector("#icon-user-header").style =
            "color: #EA8822 !important;";
        }
        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
        }, 200);
      },
      loginForm: async function () {
        document.querySelector("#area-screen").style.display = "block";

        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
          document.querySelector("#icon-user-header").style =
            "color: #EA8822 !important;";
        }, 200);
      },
      searchBoxShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#area-screen-search").style.display = "block";

        if (document.querySelector("#icon-search-header") != null) {
          document.querySelector("#icon-search-header").style =
            "color: #EA8822 !important;";
        }
        setTimeout(function () {
          document.querySelector("#area-screen-search").className =
            "home-section-1";
        }, 200);
      },
    },
    computed: {
      colorArea: function () {
        // if (window.location.href.includes("/search")  == true)
        // if (window.location.href.includes("/home")  == true)
        if (this.$route.name == "search") {
          return {
            "--cor-principal": "white",
            "--cor-texto-3": "black",
            "--cor-texto-barra": "#EA8822",
          };
        } else if (this.$route.name == "home") {
          return {
            "--cor-texto-barra": "#000",
            "--cor-texto-3": "black",
            "--cor-principal": "#21BBB3",
          };
        } else if (this.$route.name == "cart") {
          return {
            "--cor-texto-barra": "#ea8822",
            "--cor-texto-3": "black",
            "--cor-principal": "#fff",
          };
        } else {
          return {
            "--cor-texto-barra": "#fff",
          };
        }
      },
      showSearchIcon: function () {
        return this.$route.name != "search";
      },
      showCartIcon: function () {
        return this.$route.name != "cart";
      },
      invertIconColor() {
        return this.$route.name == "search" || this.$route.name == "cart";
      },
    },
  };
</script>

<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style>
  .title-with-strap.white::after,
  h2.title-with-strap.white::after {
    background-color: var(--cor-texto-barra) !important;
  }
  header h2,
  header a,
  header,
  header i,
  header .white {
    color: var(--cor-texto-3) !important;
  }
  .header-holder {
    z-index: 999;
  }
  #icon-user-header,
  #icon-search-header {
    font-size: 2.8vh !important;
    margin-right: 15px !important;
    display: block !important;
    transition: 700ms;
    margin-left: 0 !important;
  }
  #icon-user-header:hover,
  #icon-search-header:hover,
  #icon-cart-header:hover {
    cursor: pointer;
  }
  #icon-cart-header {
    width: 33px;
    height: 33px;
  }
  #icon-cart-header-div {
    margin-right: 10px;
    position: relative;
    width: 38px;
    height: 34px;
  }
  .invertColor {
    filter: invert(1);
  }
  .bcRouter:hover {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }
  .menu {
    padding-left: 7vw;
  }
  .menu-item:hover {
    color: var(--cor-principal);
    text-decoration: underline;
    cursor: pointer;
  }
  .links {
    color: inherit;
    text-decoration: none;
  }

  @media only screen and (max-width: 640px) {
    #icon-user-header {
      margin-right: 0px !important;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
</style>
