var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-holder"},[_c('header',{style:(_vm.colorArea)},[_c('div',{staticClass:"header-coluna-esq"},[_c('h2',{staticClass:"title-with-strap white"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"breadcrumb-wrapper"},[_c('div',{staticClass:"breadcrumb"},[_c('router-link',{staticClass:"bcRouter",attrs:{"to":"/home"}},[_vm._v(" Home ")]),(_vm.breadcrums.area_id != null)?_c('router-link',{staticClass:"bcRouter",attrs:{"to":'/area/' +
              _vm.breadcrums.area_id +
              '-' +
              _vm.breadcrums.area_name.replaceAll(' ', '-').toLowerCase()}},[_vm._v(" "+_vm._s(_vm.breadcrums.area_name)+" ")]):_vm._e(),(_vm.breadcrums.category_id != null)?_c('router-link',{staticClass:"bcRouter",attrs:{"to":'/categoria/' +
              _vm.breadcrums.category_id +
              '-' +
              _vm.breadcrums.category_name.replaceAll(' ', '-').toLowerCase()}},[_vm._v(" "+_vm._s(_vm.breadcrums.category_name)+" ")]):_vm._e(),(_vm.breadcrums.family_id != null && _vm.breadcrums.family_id != '0')?_c('router-link',{staticClass:"bcRouter",attrs:{"to":'/familia/' +
              _vm.breadcrums.family_id +
              '-' +
              _vm.breadcrums.family_name.replaceAll(' ', '-').toLowerCase()}},[_vm._v(" "+_vm._s(_vm.breadcrums.family_name)+" ")]):_vm._e(),(_vm.breadcrums.product_id != null)?_c('router-link',{staticClass:"bcRouter",attrs:{"to":'/produto/' +
              _vm.breadcrums.product_id +
              '-' +
              _vm.breadcrums.product_name.replaceAll(' ', '-').toLowerCase()}},[_vm._v(" "+_vm._s(_vm.breadcrums.product_name)+" ")]):_vm._e(),(_vm.breadcrums.final_place != null)?_c('a',{staticClass:"bcRouter"},[_vm._v(" "+_vm._s(_vm.breadcrums.final_place)+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"header-coluna-dir"},[(_vm.showSearchIcon)?_c('div',{staticStyle:{"color":"#fff !important"},on:{"click":function($event){return _vm.searchBoxShow()}}},[_c('i',{staticClass:"fas fa-search",attrs:{"id":"icon-search-header"}})]):_vm._e(),(_vm.user_name)?_c('div',{on:{"click":function($event){return _vm.updateUserShow()}}},[_c('i',{staticClass:"far fa-user",attrs:{"id":"icon-user-header"}})]):_vm._e(),(!_vm.user_name)?_c('div',{on:{"click":function($event){return _vm.loginForm()}}},[_c('i',{staticClass:"far fa-user",attrs:{"id":"icon-user-header"}})]):_vm._e(),_c('div',{staticClass:"texto-font-2 white"},[_vm._v("Emotional Technology")])])]),_c('AreaUser'),_c('SearchBox')],1)}
var staticRenderFns = []

export { render, staticRenderFns }