<template>
  <div>
    <section id="topo" class="seccao header-fixo">
      <Header
        :title="family_data.area_name"
        :breadcrums="breadcrums"
        :cartItems="cartItems"
      />

      <div class="nav-fixed">
        <nav>
          <div class="menu-container swiper-container">
            <div class="menu swiper-wrapper">
              <div
                :key="'/categoria/' + item.id"
                v-for="item in menu"
                class="swiper-slide"
              >
                <div
                  :class="[
                    family_data.category_id === item.id ? 'active' : '',
                    'menu-item',
                  ]"
                >
                  <router-link
                    class="links"
                    :key="'/categoria/' + item.id"
                    :to="{ path: '/categoria/' + item.id }"
                    >{{ item.name }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="nav-swiper-button-next"></div>
          <div class="nav-swiper-button-prev"></div>
        </nav>
      </div>

      <main>
        <div class="main-inner-wrapper">
          <div class="main-coluna-conteudo">
            <div class="main-coluna-conteudo-inner-wrapper">
              <h2 class="title-with-strap">{{ family_data.name }}</h2>
              <h3 class="h1">{{ family_data.title }}</h3>
              <div class="font-size-24 font-weight-500">
                {{ family_data.description }}
              </div>
            </div>
          </div>
          <div class="main-coluna-imagens">
            <div class="imagens-wrapper swiper-wrapper">
              <div
                v-if="family_data.video != null"
                class="swiper-slide video-container"
              >
                <video class="prod-video" autoplay loop muted>
                  <source
                    :src="
                      '/assets/fotos_catalogo/products/' + family_data.video
                    "
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div
                v-if="family_data.img_1 != null"
                class="swiper-slide"
                :style="{
                  'background-image':
                    'url(/assets/fotos_catalogo/products/' +
                    family_data.img_1 +
                    ')',
                }"
              ></div>
              <div
                v-if="family_data.img_2 != null"
                class="swiper-slide"
                :style="{
                  'background-image':
                    'url(/assets/fotos_catalogo/products/' +
                    family_data.img_2 +
                    ')',
                }"
              ></div>
              <div
                v-if="family_data.img_3 != null"
                class="swiper-slide"
                :style="{
                  'background-image':
                    'url(/assets/fotos_catalogo/products/' +
                    family_data.img_3 +
                    ')',
                }"
              ></div>
              <div
                v-if="family_data.img_1 == null"
                class="swiper-slide"
                :style="{ 'background-image': 'url(' + imgProd + ')' }"
              ></div>
            </div>
            <!-- <div class="swiper-pagination"></div> -->
            <div class="slider-swiper-button-next"></div>
          </div>
        </div>
      </main>
      <!-- <a v-scroll-to="'#feed'" class="footer-avancar"></a> -->
    </section>

    <section id="feed" class="seccao header-fixo">
      <!-- <a v-scroll-to="'#topo'" class="header-voltar white"></a> -->

      <main class="listagem-produtos-com-footer">
        <div class="main-inner-wrapper">
          <div class="main-coluna-100p">
            <h2 class="title-with-strap">{{ family_data.name }}</h2>
            <div class="produtos-outer-wrapper swiper-container-a">
              <div class="produtos-wrapper swiper-wrapper">
                <div
                  v-for="item in feed_families"
                  :key="item.id"
                  class="produto-item swiper-slide"
                >
                  <router-link
                    class="links"
                    :key="'/familia/' + item.id"
                    :to="{ path: '/familia/' + item.id }"
                  >
                    <div class="produto-item-inner-wrapper">
                      <div
                        v-if="item.image == ''"
                        class="imagem-wrapper"
                        :style="{ 'background-image': 'url(' + imgProd + ')' }"
                      ></div>
                      <div
                        v-if="item.image != ''"
                        class="imagem-wrapper"
                        :style="{
                          'background-image':
                            'url(/assets/fotos_catalogo/products/' +
                            item.image +
                            ')',
                        }"
                      ></div>
                      <div class="conteudo-wrapper">
                        <h4 class="produto-nome">{{ item.name }}</h4>
                        <div class="produto-subtitulo font-size-22">
                          {{ item.feed_description }}
                        </div>
                        <div
                          v-if="item.price > 0 && isLogged"
                          class="produto-preco"
                          style="line-height: 23px"
                        >
                          <span style="font-weight: normal">desde</span>
                          <div style="color: var(--cor-principal)">
                            {{
                              parseInt(item.price)
                                .toFixed(0)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                            }}€
                          </div>
                        </div>
                        <div v-else class="produto-preco">
                          <span>Preço sob consulta</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>

                <div
                  v-for="item in feed_products"
                  :key="item.id"
                  class="produto-item swiper-slide"
                >
                  <router-link
                    class="links"
                    :key="'/produto/' + item.id"
                    :to="{ path: '/produto/' + item.id + '-' + item.name }"
                  >
                    <div class="produto-item-inner-wrapper">
                      <div
                        v-if="item.image == ''"
                        class="imagem-wrapper"
                        :style="{ 'background-image': 'url(' + imgProd + ')' }"
                      ></div>
                      <div
                        v-if="item.image != ''"
                        class="imagem-wrapper"
                        :style="{
                          'background-image':
                            'url(/assets/fotos_catalogo/products/' +
                            item.image +
                            ')',
                        }"
                      ></div>
                      <div class="conteudo-wrapper">
                        <h4 class="produto-nome">{{ item.name }}</h4>
                        <div class="produto-subtitulo font-size-22">
                          {{ item.feed_description }}
                        </div>
                        <div
                          v-if="item.discount_price > 0"
                          class="produto-preco"
                          style="line-height: 23px"
                        >
                          <span
                            style="
                              font-weight: lighter;
                              text-decoration: line-through;
                              color: #666;
                            "
                            >{{
                              parseInt(item.price)
                                .toFixed(0)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                            }}€</span
                          >
                          <div style="color: var(--cor-2)">
                            {{
                              parseInt(item.discount_price)
                                .toFixed(0)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                            }}€
                          </div>
                        </div>
                        <div
                          v-else-if="item.price > 0 && isLogged"
                          class="produto-preco"
                          style="line-height: 23px"
                        >
                          <span style="font-weight: normal">desde</span>
                          <div style="color: var(--cor-principal)">
                            {{
                              parseInt(item.price)
                                .toFixed(0)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                            }}€
                          </div>
                        </div>

                        <div class="produto-preco" v-else>
                          <span>Preço sob consulta</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="produtos-swiper-button-prev" v-if="createSlider"></div>
            <div class="produtos-swiper-button-next" v-if="createSlider"></div>
          </div>
        </div>
      </main>
      <Footer />
    </section>

    <div class="loadingLayer" :style="'display: ' + loadingStyle">
      <img src="/assets/imagens/loading.gif" />
      <h3>A carregar...</h3>
    </div>
  </div>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";

  export default {
    name: "familia",
    components: {
      Header,
      Footer,
    },
    props: ["backdoor"],
    data() {
      return {
        imgProd: "/assets/imagens/default.png",
        imgFam: "/assets/imagens/default.png",
        imgImg: "/assets/imagens/default.png",
        family_id: this.$route.params.id,
        menu: {},
        family_data: {},
        feed_families: [],
        feed_products: [],
        breadcrums: {},
        loadingStyle: "flex",
        isLogged: window.sessionStorage.user_level,
      };
    },
    computed: {
      createSlider: function () {
        let familiesItems =
          this.feed_families != undefined
            ? Object.keys(this.feed_families).length
            : 0;
        let productsItems =
          this.feed_products != undefined
            ? Object.keys(this.feed_products).length
            : 0;
        return familiesItems + productsItems > 5;
      },

      materiaisCarrinho() {
        this.n;
        let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

        return temp;
      },
      m() {
        this.backdoor;
        return this.n;
      },
      cartItems() {
        this.n;
        let len = this.materiaisCarrinho.length || 0;

        return len;
      },
    },
    methods: {
      collect_feed_data: async function () {
        var operation = "families/feed/" + this.family_id;
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        this.loadingStyle = "flex";

        var feed_data = await this.call_api_get(url, headers);

        this.feed_families = feed_data.families;
        this.feed_products = feed_data.products;
        console.log(feed_data, "aqui222");
        // document.querySelector(".loadingLayer").style.display = "none"

        let vm = this;
        setTimeout(function () {
          vm.loadingStyle = "none";
        }, 500);
      },
      collect_page_data: async function () {
        var operation = "families/" + this.family_id;
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        this.loadingStyle = "flex";

        var family_data = await this.call_api_get(url, headers);

        this.family_data = family_data.area[0];
        this.menu = family_data.categories;

        this.breadcrums = {
          area_name: family_data.area[0].area_name,
          area_id: family_data.area[0].g_area_id,
          category_name: family_data.area[0].category_name,
          category_id: family_data.area[0].category_id,
          family_name: family_data.area[0].name,
          family_id: this.$route.params.id,
        };

        var color_code = this.family_data.area_color;

        var styleNode = document.createElement("style");
        styleNode.type = "text/css";
        var styleText = document.createTextNode(
          ":root {--cor-principal: " +
            color_code +
            " !important;--cor-1: " +
            color_code +
            " !important;}"
        );
        styleNode.appendChild(styleText);

        document.getElementsByTagName("head")[0].appendChild(styleNode);

        let vm = this;
        setTimeout(function () {
          vm.loadingStyle = "none";
        }, 500);
      },
      call_api_post: async function (url, headers, method) {
        try {
          var response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(headers, null, 2),
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
    created() {
      this.collect_page_data();
      this.collect_feed_data();
    },
    watch: {
      "$route.params.id"(newId, oldId) {
        console.log(newId);
        this.family_id = newId;
        this.collect_page_data();
        this.collect_feed_data();
      },
    },
    mounted: function () {
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true

      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }

      $(document).ready(function () {
        $(".imagens-wrapper").each(function () {
          var $imagensWrapper = $(this);

          if ($imagensWrapper.children().length > 1) {
            $(this).parent().addClass("swiper-container");
            //Slider
            var mySwiper = new Swiper("main .swiper-container", {
              slidesPerView: 1,
              slidesPerGroup: 1,
              fadeEffect: {
                crossFade: true,
              },
              autoplay: {
                delay: 1200,
                disableOnInteraction: true,
              },
              infinite: true,
              pagination: {
                el: ".swiper-pagination",
              },
              navigation: {
                nextEl: ".slider-swiper-button-next",
              },
            });
          }
        });
        var menuSlider = new Swiper("footer .menu-container.swiper-container", {
          navigation: {
            nextEl: ".menu-swiper-button-next",
            prevEl: ".menu-swiper-button-prev",
          },
          autoplay: {
            delay: 2500,
            disableOnInteraction: true,
          },
          infinite: true,
          breakpoints: {
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 4,
              slidesPerGroup: 1,
            },
          },
        });
        $("nav").each(function () {
          var navSlider = new Swiper("nav .menu-container.swiper-container", {
            navigation: {
              nextEl: ".nav-swiper-button-next",
              prevEl: ".nav-swiper-button-prev",
            },
            autoplay: {
              delay: 2500,
              disableOnInteraction: true,
            },
            infinite: true,
            breakpoints: {
              640: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 4,
                slidesPerGroup: 1,
              },
            },
          });
        });
        $(".produtos-outer-wrapper").each(function () {
          var navSlider = new Swiper(".produtos-outer-wrapper", {
            navigation: {
              nextEl: ".produtos-swiper-button-next",
              prevEl: ".produtos-swiper-button-prev",
            },
            autoplay: {
              delay: 2500,
              disableOnInteraction: true,
            },
            infinite: true,
            breakpoints: {
              640: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 5,
                slidesPerGroup: 1,
              },
            },
          });
        });
      });
    },
    updated() {
      // let isLogged = window.sessionStorage.getItem('user_id')
      // const result = isLogged == null ? false : true

      // if(!result) {
      //     window.location.href = "/login"
      // }
      // else {
      //     console.log("session is valid")
      // }

      $(document).ready(function () {
        $(".imagens-wrapper").each(function () {
          var $imagensWrapper = $(this);

          if ($imagensWrapper.children().length > 1) {
            $(this).parent().addClass("swiper-container");
            //Slider
            var mySwiper = new Swiper("main .swiper-container", {
              slidesPerView: 1,
              slidesPerGroup: 1,
              fadeEffect: {
                crossFade: true,
              },
              autoplay: {
                delay: 2500,
                disableOnInteraction: true,
              },
              infinite: true,
              pagination: {
                el: ".swiper-pagination",
              },
              navigation: {
                nextEl: ".slider-swiper-button-next",
              },
            });
          }
        });
        var menuSlider = new Swiper("footer .menu-container.swiper-container", {
          navigation: {
            nextEl: ".menu-swiper-button-next",
            prevEl: ".menu-swiper-button-prev",
          },
          autoplay: {
            delay: 2500,
            disableOnInteraction: true,
          },
          infinite: true,
          breakpoints: {
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 4,
              slidesPerGroup: 1,
            },
          },
        });
        $("nav").each(function () {
          var navSlider = new Swiper("nav .menu-container.swiper-container", {
            navigation: {
              nextEl: ".nav-swiper-button-next",
              prevEl: ".nav-swiper-button-prev",
            },
            autoplay: {
              delay: 2500,
              disableOnInteraction: true,
            },
            infinite: true,
            breakpoints: {
              640: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 4,
                slidesPerGroup: 1,
              },
            },
          });
        });
        $(".produtos-outer-wrapper").each(function () {
          var navSlider = new Swiper(".produtos-outer-wrapper", {
            navigation: {
              nextEl: ".produtos-swiper-button-next",
              prevEl: ".produtos-swiper-button-prev",
            },
            breakpoints: {
              640: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 5,
                slidesPerGroup: 1,
              },
            },
          });
        });
      });
    },
  };
</script>

<style src="@/assets/style.css"></style>
<style src="@/assets/produtos.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet {
    display: none;
  }
  .swipe-menu {
    position: relative;
    width: 100%;
    height: auto !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .links {
    color: inherit;
    text-decoration: none;
  }

  .video-container {
    display: flex;
    background: #000;
  }

  /* .video-container {
        display: flex;
        justify-content: center;
        width: 100%;
        background: #000 !important;
        background-size: cover;
    } */
  .prod-video {
    max-height: 100%;
    height: 100%;
    align-self: center;
  }

  .slider-swiper-button-next {
    background-image: url("/assets/imagens/arrow_next_gray.svg");
  }

  footer.footer-contactos {
    height: 10vh !important;
  }

  @media only screen and (max-width: 640px) {
    .produtos-wrapper .produto-item {
      min-width: 100%;
    }
    .seccao main .main-inner-wrapper .main-coluna-100p {
      max-width: calc(100vw - 100px) !important;
    }
    .produtos-wrapper .links {
      width: 100%;
    }
    .seccao.header-fixo main.listagem-produtos-com-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .produtos-wrapper.swiper-wrapper {
      width: 100%;
    }
    .produtos-swiper-button-prev {
      left: 5px;
    }
    .produtos-swiper-button-next {
      right: 5px;
    }

    .prod-video {
      max-height: 100%;
      height: auto;
      width: 100%;
      align-self: center;
    }
    .listagem-produtos-com-footer {
      padding-bottom: 5vh;
    }
  }
</style>
