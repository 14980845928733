<template>
  <footer class="footer-contactos">
    <div class="footer-coluna-100p">
      <div class="flex-row align-center justify-space-between">
        <div class="container-contactos flex-row align-center">
          <div class="contacto-footer-item morada">
            <span class="icone-contacto"
              ><img src="/assets/imagens/icone_morada.svg" alt="icone morada"
            /></span>
            <span class="texto-contacto"
              >Beloura Office Park Edf. 10, 2710-693 Sintra</span
            >
          </div>
          <div class="contacto-footer-item telefone">
            <span class="icone-contacto"
              ><img
                src="/assets/imagens/icone_telefone.svg"
                alt="icone telefone"
            /></span>
            <span class="texto-contacto"
              ><a href="tel:+351219100200">+351 219 100 200</a></span
            >
          </div>
          <div class="contacto-footer-item fax">
            <span class="icone-contacto"
              ><img src="/assets/imagens/icone_fax.svg" alt="icone fax"
            /></span>
            <span class="texto-contacto"
              ><a href="tel:+351219100299">+351 219 100 299</a></span
            >
          </div>
          <div class="contacto-footer-item emaial">
            <span class="icone-contacto"
              ><img src="/assets/imagens/icone_email.svg" alt="icone email"
            /></span>
            <span class="texto-contacto"
              ><a href="mailto:info@glintt.com">info@glintt.com</a></span
            >
          </div>
          <div class="contacto-footer-item emaial">
            <span class="icone-contacto"
              ><img src="@/assets/imagens/icone_phone.svg" alt=""
            /></span>
            <span class="texto-contacto"
              ><a>Assistência Técnica: 808 200 419</a></span
            >
          </div>
        </div>
        <div class="logotipo-footer">
          <img
            src="/assets/imagens/glintt_logo_footer.svg"
            alt="logotipo Glintt"
          />
        </div>
      </div>
      <div class="text-center">
        Copyright {{ getFullYear() }} | Boosted by
        <a href="https://byd.pt" target="_blank">BYD</a>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "Footer",
    data() {
      return {};
    },
    methods: {
      getFullYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
      },
    },
  };
</script>

<style scoped>
  section footer.footer-contactos .footer-coluna-100p {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
  }
  .links {
    color: inherit;
    text-decoration: none;
  }
</style>
